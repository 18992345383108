import { template as template_4dd906d482aa4eedadf202d25196c288 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4dd906d482aa4eedadf202d25196c288(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
