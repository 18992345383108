import { template as template_bcd19058991a4dafa06ace58afc4a591 } from "@ember/template-compiler";
const SidebarSectionMessage = template_bcd19058991a4dafa06ace58afc4a591(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
